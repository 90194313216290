<!-- =========================================================================================
    คำอธิบาย 3 บรรทัด ตีเป็น 1 record
    เฉลิี่ยจะมี 6 บรรทัด หรือ 2 record สำหรับ ข้อตกลงการเช่าชุด

    ----------------------------------------------------------------------------------------
  1 Record ความสูง 64px หรือประมาณ 16.93333
  1 px ~ 0.2645833333 mm
  1 Records ประมาณ 16.9333333312 mm
  5 Records ประมาณ 85 mm

   1. up to 3 records (1 record + 6 lines) 200mm / 180mm (html / pagesize)
   2. up to 4 - 5 records (2 records + 3 records)  220mm / 200mm (html / pagesize)
   3. up to 8 - 12 records (2 records + 5 records)  260mm / 240mm  (html / pagesize)
   ==========================================================================================s
   4. up to 13 - 17 records  300mm / 280mm (html / pagesize)
   5. up to 18 - 22 records  340mm / 320mm (html / pagesize)
   6. up to 23 - 27 records  380mm / 360mm (html / pagesize)
   7. up to 28 - 32 records  420mm / 400mm (html / pagesize)
========================================================================================== -->

<template lang="pug">
  CommonRct

</template>

<script>

import CommonRct from './CommonRct.vue'

export default {

  data() {
    return {
      props: {
        orderid: String,
      },
      height: 125,
      wh: "80mm 3000mm",
      hg: "700mm !important",
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      },
      orderInfo: {},
      switch1: true,
      qrPrint: 2,
      pageNum: 1,
      pageNumQr:1,
      pageSize: 12,
      productList: [],
      transformList: [],
      transformListQr:[],
      categories: [],
      textures: [],
      colors: [],
      sizes: [],
      NumberOfLine:0, recpCategory: ""
    };
  },



  watch:{
    switch1(){

      if(this.switch1 === true){
        this.qrPrint = 2;
      }else{
        this.qrPrint = 1;
      }

    }
  },
  async mounted() {
    this.$emit("setAppClasses", "invoice-page");
  },


  components: { CommonRct }
};
</script>

<style   >


.invoice-container {
      background-color: white;
      margin : 0 auto  !important ;
      font-family: "SukhumvitSet-Medium";
      font-size: 0.9rem;
      width: 80mm !important;
      height: 220mm;
}
@media print {

  @page { size: 80mm 200mm; }



}

</style>
